import React from 'react'
import equipo from '../assets/about/equipo.png';

const Equipo = () => {
  return (
    <div className="w-full bg-down bg-contain bg-no-repeat bg-bottom">
        <div className="mx-auto max-w-2xl text-center pt-4 px-4">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Conoce a nuestro equipo</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Nuestro equipo es multidisciplinario con experiencia en diferentes industrias
            </p>
        </div>
        <div className='container mx-auto'>
          <img className='object-contain' src={equipo} alt="" />
        </div>
    </div>
  )
}

export default Equipo