import React from "react";
import { useEffect } from "react";
import Header from "../Components/Header";
import Profesional from "../Components/Profesional";
import BuceoProfesional from "../Components/BuceoProfesional";
import Footer from "../Components/Footer";


const Buceo = () => {
  useEffect(() => {
      const loadLocomotiveScroll = async () => {
          const LocomotiveScroll = (await import("locomotive-scroll")).default;
          new LocomotiveScroll();
      };

      loadLocomotiveScroll();
  }, []);
  return (
      <div className="h-full overflow-x-hidden">
          <Header />
          <Profesional />
          <BuceoProfesional />
          <Footer />
      </div>
  )
}

export default Buceo