import React from 'react'
import { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HeroContacto from '../Components/HeroContacto';
import Contact2 from '../Components/Contact2';

const Contacto = () => {
    useEffect(() => {
        const loadLocomotiveScroll = async () => {
            const LocomotiveScroll = (await import("locomotive-scroll")).default;
            new LocomotiveScroll();
        };
    
        loadLocomotiveScroll();
    }, []);
    return (
        <div className="h-full overflow-x-hidden">
            <Header />
            <HeroContacto />
            <Contact2 />
            <Footer />
        </div>
    )
}

export default Contacto