import React from 'react'
/*
import timeline from '../assets/about/timeline.png';
*/
const Historia = () => {
  return (
    <section className='bg-blue-950'>
        <div className='container mx-auto flex flex-col items-center justify-center'>
            {/* título */}
            <div className='mx-auto max-w-2xl sm:text-center pt-4'>
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mt-6">
                    Frogman en el tiempo
                </h2>
            </div>
            <div className='flex flex-col md:flex-row py-4 gap-4'>
                {/* historia */}
                <div className='py-4'>
                    <p className='text-white text-center'>
                    Fundada en 2014 por Sergio y Álvaro Contreras, Frogman comenzó como una tienda de buceo profesional. Con el tiempo, se ha transformado en un líder en buceo profesional y robótica submarina. Gracias a alianzas estratégicas internacionales y equipos de última generación, Frogman ofrece servicios de alta calidad, destacándose por su compromiso con la seguridad y la innovación.
                    </p>
                </div>
            </div>
        </div>
        {/* timeline */}
        {/*
        <div>
            <img src={timeline} alt="" />
        </div>
        */}
        <div className='container'>
            <ol className="border-l border-white md:flex md:justify-center md:gap-6 md:border-l-0 md:border-t">
            <li>
                <div className="flex-start flex items-center pt-2 md:block md:pt-0">
                <div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-white md:-mt-[5px] md:ml-0 md:mr-0"></div>
                <p className="mt-2 text-sm text-white">
                    2014
                </p>
                </div>
                <div className="ml-4 mt-2 pb-5 md:ml-0">
                <h4 className="mb-1.5 text-xl text-white font-semibold">
                    Comenzamos
                </h4>
                <p className="mb-3 text-white">
                    Se crea Frogman como tienda de <b>buceo profesional</b>.
                </p>
                </div>
            </li>
            <li>
                <div className="flex-start flex items-center pt-2 md:block md:pt-0">
                <div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-white md:-mt-[5px] md:ml-0 md:mr-0"></div>
                <p className="mt-2 text-sm text-white">
                    2018
                </p>
                </div>
                <div className="ml-4 mt-2 pb-5 md:ml-0">
                <h4 className="mb-1.5 text-xl text-white font-semibold">
                    Alianzas internacionales
                </h4>
                <p className="mb-3 text-white">
                    Establecemos alianzas internacionales con empresas creadoras de equipos de buceo profesional como <b>Divers Supply</b>.
                </p>
                </div>
            </li>
            <li>
                <div className="flex-start flex items-center pt-2 md:block md:pt-0">
                <div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-white md:-mt-[5px] md:ml-0 md:mr-0"></div>
                <p className="mt-2 text-sm text-white">
                    año
                </p>
                </div>
                <div className="ml-4 mt-2 pb-5 md:ml-0">
                <h4 className="mb-1.5 text-xl text-white font-semibold">
                    De Chile al mundo
                </h4>
                <p className="mb-3 text-white">
                    Participamos en <b>ferias internacionales de buceo</b> en USA.
                </p>
                </div>
            </li>
            <li>
                <div className="flex-start flex items-center pt-2 md:block md:pt-0">
                <div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-white md:-mt-[5px] md:ml-0 md:mr-0"></div>
                <p className="mt-2 text-sm text-white">
                    2022
                </p>
                </div>
                <div className="ml-4 mt-2 pb-5 md:ml-0">
                <h4 className="mb-1.5 text-xl text-white font-semibold">
                    Mejorando
                </h4>
                <p className="mb-3 text-white">
                    Ampliamos nuestros servicios a <b>buceo profesional</b> y <b>robótica submarina</b>.
                </p>
                </div>
            </li>
            </ol>
        </div>
        {/*
        <div className='container mx-auto'>
            <ol class="flex items-center items-start sm:flex">
                <li class="relative mb-6 sm:mb-0">
                    <div class="flex items-center">
                        <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg class="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                            </svg>
                        </div>
                        <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                    </div>
                    <div class="mt-3 sm:pe-8">
                        <h3 class="text-lg text-white font-semibold text-gray-900 dark:text-white">Iniciamos</h3>
                        <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">2014</time>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Se crea Frogman como tienda de buceo profesional.</p>
                    </div>
                </li>
                <li class="relative mb-6 sm:mb-0">
                    <div class="flex items-center">
                        <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg class="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                            </svg>
                        </div>
                        <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                    </div>
                    <div class="mt-3 sm:pe-8">
                        <h3 class="text-lg text-white font-semibold text-gray-900 dark:text-white">Alianzas</h3>
                        <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">2018</time>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Establecemos alianzas internacionales con empresas creadoras de equipos de buceo profesional como Divers Supply.</p>
                    </div>
                </li>
                <li class="relative mb-6 sm:mb-0">
                    <div class="flex items-center">
                        <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg class="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                            </svg>
                        </div>
                        <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                    </div>
                    <div class="mt-3 sm:pe-8">
                        <h3 class="text-lg text-white font-semibold text-gray-900 dark:text-white">De chile al mundo</h3>
                        <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">año</time>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Participamos en ferias internacionales de buceo en USA.</p>
                    </div>
                </li>
                <li class="relative mb-6 sm:mb-0">
                    <div class="flex items-center">
                        <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg class="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                            </svg>
                        </div>
                        <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                    </div>
                    <div class="mt-3 sm:pe-8">
                        <h3 class="text-lg text-white font-semibold text-gray-900 dark:text-white">Mejorando</h3>
                        <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">2022</time>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Ampliamos nuestros servicios a buceo profesional y robótica submarina.</p>
                    </div>
                </li>
            </ol>
        </div>
        */}
    </section>
  )
}

export default Historia