import React from 'react'
import { useEffect } from 'react';
import RoboticaSubmarina from '../Components/RoboticaSubmarina';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ServiciosRobotica from '../Components/ServiciosRobotica';
import Equipos from '../Components/Equipos';

const Robotica = () => {
  useEffect(() => {
    const loadLocomotiveScroll = async () => {
        const LocomotiveScroll = (await import("locomotive-scroll")).default;
        new LocomotiveScroll();
    };

    loadLocomotiveScroll();
}, []);
return (
    <div className="h-full overflow-x-hidden">
        <Header />
        <RoboticaSubmarina />
        <ServiciosRobotica />
        <Equipos />
        <Footer />
    </div>
)
}

export default Robotica