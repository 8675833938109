import React from 'react'
import { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HeroPolitica from '../Components/HeroPolitica';

const Politica = () => {
  useEffect(() => {
    const loadLocomotiveScroll = async () => {
        const LocomotiveScroll = (await import("locomotive-scroll")).default;
        new LocomotiveScroll();
    };

    loadLocomotiveScroll();
}, []);
return (
    <div className="h-full overflow-x-hidden">
        <Header />
        <HeroPolitica />
        <Footer />
    </div>
)
}

export default Politica