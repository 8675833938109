"use client";
import { FaCheckCircle } from "react-icons/fa";

const includedFeatures = [
    {
        name: 'Servicios de Inspección',
        description: 'Inspección submarina de centros de cultivo y sus redes, inspección de líneas de fondeo y del fondo marino. Posicionamiento satelital submarino de puntos de interés',
    },
    {
        name: 'Costurado y mantención de Redes',
        description: 'Costura para mantención de redes realizada con ROV y herramientas propias para estos efectos.',
    },
    {
        name:'Recuperación de Restos Náufragos',
        description: 'Balizado y recuperación de restos náufragos, contrapesos y otros materiales. Limpieza de fondos marinos',
    },
    {
        name: 'Retiro de Mortalidad',
        description: 'Realizamos retiro de mortalidad con empuje con pala  o quiñe.',
    },
    {
        name: 'Corte Submarino',
        description: 'Corte realizado con herramientas con ROV para retiro de redes y cabullería.',
    },
]

const ServiciosRobotica = () => {
  return (
    <div>
        <div className="bg-colores bg-no-repeat bg-contain py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Explora nuestros servicios</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Ofrecemos servicios avanzados de robótica submarina, diseñados para satisfacer las necesidades más exigentes de nuestros clientes. Utilizamos tecnología de vanguardia en vehículos operados remotamente (ROVs) y otros equipos robóticos para realizar inspecciones, mantenimiento y reparaciones en entornos subacuáticos. Nuestro equipo de expertos está altamente capacitado para operar estos sistemas, garantizando precisión y eficiencia en cada proyecto. Con nuestras soluciones de robótica submarina, proporcionamos una alternativa segura y efectiva a las operaciones de buceo tradicionales, minimizando riesgos y optimizando resultados.
                    </p>
                </div>
                {/* cuadros
                <div className="max-w-full flex flex-col md:flex-row justify-around gap-2">
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6 transition-all">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Básico</h3>
                            <h2><span>Profundidad:</span> 20 metros</h2>
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6 transition-all">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Intermedio</h3>
                            <h2><span>Profundidad:</span> 36 metros</h2>
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6 transition-all">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Buceo Comercial</h3>
                            <h2><span>Profundidad:</span> A requerimiento</h2>
                        </div>
                    </div>
                </div>
                 */}
                <div className="max-w-full flex flex-col md:flex-row justify-around gap-2 mt">
                    <div className="mx-auto mt-3 w-full md:w-auto">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">¿Qué ofrecemos?</h3>
                            <ul className="list-disc list-inside text-gray-600 flex flex-col sm:grid sm:grid-cols-2">
                                {includedFeatures.map((feature, index) => (
                                    <li key={index} className="flex gap-x-4 gap-y-2">
                                    <FaCheckCircle aria-hidden="true" className="h-6 w-5 flex-none text-accent" />
                                    <div className="flex-col justify-center">
                                        <h3>{feature.name}</h3>
                                        <p className="" key={index}>{feature.description}</p>
                                    </div>                                    
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default ServiciosRobotica