import React from 'react'
import notion from '../assets/important/notion.png'
import buk from '../assets/important/buk.svg'

const Tecnologias = () => {
  return (
    <section>
        <div className="bg-colores bg-no-repeat bg-contain py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Herramientas Tecnológicas</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Utilizamos herramientas avanzadas como Notion para la reportería y Buk para el control interno. Estas herramientas nos permiten gestionar nuestros proyectos y recursos de manera eficiente, asegurando un servicio de alta calidad para nuestros clientes.
                    </p>
                </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                    {/* col1 */}
                    <div className="relative flex flex-col content-between text-center lg:text-right justify-stretch pl-16">
                        <dd>
                            <img 
                                src={notion}
                                className="object-contain w-full mb-4 h-24"
                                alt=""
                            />
                        </dd>
                        <dd className="mt-2 text-base leading-7">
                            {/* descripción */}
                            Desarrollamos un sistema de reportería en la nube en la plataforma Notion, esta nos permite subir videos, imágenes y diagramas de los trabajos realizados con la operación de ROVs, obteniendo KPI y datos importantes para nuestros clientes.
                        </dd>
                    </div>
                    {/*
                    <div className="">
                    <img className="max-h-[200px]" src={separador} alt="" />
                    </div>
                    */}
                    {/* col2 */}
                    <div className="relative flex flex-col content-between text-center lg:text-left justify-center pl-16">
                        <dd>
                            <img 
                                src={buk}
                                className="object-contain w-full mb-4 h-24"
                                alt=""
                            />
                        </dd>
                        <dd className="mt-2 text-base leading-7">
                            {/* descripción */}
                            Utilizamos las herramientas de la plataforma BUK para gestionar el control interno de personal y la documentación legal. Así optimizamos procesos administrativos, aseguramos información actualizada y accesible; y mejoramos la eficiencia en la gestión de recursos humanos.
                        </dd>
                    </div>
                </dl>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Tecnologias