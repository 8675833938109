import React from 'react'
import Badge from './Badge'
import Separator from './Separator'
import video from "../assets/hero/video.mp4";

const HeroContacto = () => {
    return (
        <section className="h-[80vh] xl:h-screen relative text-white">
            <div className="bg-hero_overlay opacity-45 absolute w-full h-full z-10 bg-primary/[0.01]"></div>
            <video 
                autoPlay 
                loop 
                muted 
                className="absolute top-0 left-0 w-full h-full object-cover"
            >
                <source src={video} type="video/mp4" />
                Tu navegador no soporta el tag de video.
            </video>
            <div className="container mx-auto h-full flex flex-col xl:flex-row items-center z-30 relative">
                <div data-scroll data-scroll-speed="0.4" className="flex-1 flex flex-col text-center justify-center items-center xl:pb-12 gap-10 h-full">
                    <div className="flex flex-col items-center pt-16 md:pt-9">
                        <Badge containerStyles="hidden xl:flex xl:w-[180px] xl:h-[140px]" />
                        <h1 className="h1 text-4xl lg:text-6xl text-white">
                            <span className="text-white">CONTACTO</span>
                        </h1>
                    </div>                
                    <Separator />
                    <p>
                    Estamos siempre disponibles para atender tus consultas y necesidades. Si deseas obtener más información sobre nuestros servicios, solicitar una cotización o simplemente hablar con uno de nuestros expertos, no dudes en ponerte en contacto con nosotros. Nuestro equipo está listo para ayudarte y brindarte la mejor atención posible.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default HeroContacto