import React from 'react'
import { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HeroNosotros from '../Components/HeroNosotros';
import Equipo from '../Components/Equipo';
import Mision from '../Components/Mision';
import Historia from '../Components/Historia';

const Nosotros = () => {
    useEffect(() => {
        const loadLocomotiveScroll = async () => {
            const LocomotiveScroll = (await import("locomotive-scroll")).default;
            new LocomotiveScroll();
        };

        loadLocomotiveScroll();
    }, []);
    return (
        <div className="h-full overflow-x-hidden">
        <Header />
        <HeroNosotros />
        <Equipo />
        <Historia />
        <Mision />
        <Footer />
        </div>
    )
}

export default Nosotros