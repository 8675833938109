import React from 'react'

const Mision = () => {
  return (
    <section id='misión' className='bg-colores bg-contain bg-no-repeat'>
        <div className='container mx-auto py-4 flex flex-col items-center justify-center'>
            {/* título */}
            <div className='mx-auto max-w-2xl sm:text-center pt-8 mb-10'>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Nuestros valores
                </h2>
            </div>
            <div className='flex flex-col md:flex-row items-center justify-center px-4 py-4 gap-8'>
                {/* visión */}
                <div className='py-4 justify-center max-w-[500px] my-4'>
                    <h3 className='text-gray-900 text-xl'>
                        Visión
                    </h3>
                    <p className='text-gray-600'>
                        Ser la empresa líder en buceo profesional y
                        robótica submarina del sur del país,
                        reconocida por nuestra innovación, calidad y
                        compromiso con la seguridad. Aspiramos a
                        transformar las operaciones subacuáticas en
                        la industria acuícola, estableciendo un nuevo
                        estándar de excelencia y tecnología que
                        impulse el desarrollo eficiente y responsable
                        del sector
                    </p>
                </div>
                {/* misión */}
                <div className='py-4 justify-center max-w-[500px] my-4'>
                    <h3 className='text-gray-900 text-xl'>
                        Misión
                    </h3>
                    <p className='text-gray-600'>
                        Brindar soluciones integrales de buceo
                        profesional y robótica submarina a la
                        industria acuícola, asegurando la eficiencia
                        operativa, la seguridad y la sostenibilidad. A
                        través de nuestro equipo altamente
                        capacitado y el uso de tecnología, nos
                        comprometemos a ofrecer servicios
                        confiables que optimicen las operaciones
                        subacuáticas y promuevan el crecimiento de
                        nuestros clientes.
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Mision