"use client";
import { FaCheckCircle } from "react-icons/fa";
import buceo1 from '../assets/buceo/1.png';
import buceo3 from '../assets/buceo/3.png';

const includedFeatures = [
    {
        name: 'Mantención de centros de cultivo',
        description: 'Instalación y retiro de redes peceras y loberas desde centros de cultivo, retiro de mortalidad y mantención general de redes.',
    },
    {
        name: 'Limpieza y desinfeccion',
        description: 'Limpieza de flotadores, artefactos navales y boyas. Desinfección de artefactos navales y naves con emisión de certificado autorizado por el Servicio de Salud.',
    },
    {
        name: 'Trabajos de buceo comercial',
        description: 'Mantención, instalación y reparación de terminales marítimos y sus componentes, apoyo a naves con averías y retiro de acorbatamientos.',
    },
    {
        name:'Retiro de restos náufragos',
        description: 'Remoción submarina de restos náufragos.',
    },
]

const BuceoProfesional = () => {
  return (
    <div>
        <div className="bg-colores bg-no-repeat bg-contain py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Explora nuestros servicios</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Nos enorgullecemos de ofrecer una amplia gama de servicios submarinos diseñados para satisfacer las necesidades más exigentes. Nuestro equipo altamente cualificado y nuestra tecnología de vanguardia garantizan soluciones seguras y eficientes para cada proyecto. Descubre cómo podemos ayudarte a alcanzar tus objetivos con la máxima calidad y seguridad.
                    </p>
                </div>
                {/* cuadros */}
                <div className="max-w-full flex flex-col md:flex-row justify-around gap-2">
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6 transition-all">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Básico</h3>
                            <h2><span>Profundidad:</span> 20 metros</h2>
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6 transition-all">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Intermedio</h3>
                            <h2><span>Profundidad:</span> 36 metros</h2>
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6 transition-all">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Buceo Comercial</h3>
                            <h2><span>Profundidad:</span> A requerimiento</h2>
                        </div>
                    </div>
                </div>
                {/* qué ofrecemos */}
                <div className="max-w-full flex flex-col md:flex-row justify-around gap-2 mt">
                    <div className="mx-auto mt-3 w-full md:w-auto">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">¿Qué ofrecemos?</h3>
                            <ul className="list-disc list-inside text-gray-600 flex flex-col sm:grid sm:grid-cols-2">
                                {includedFeatures.map((feature, index) => (
                                    <li key={index} className="flex gap-x-4 gap-y-2">
                                    <FaCheckCircle aria-hidden="true" className="h-6 w-5 flex-none text-accent" />
                                    <div className="flex-col justify-center">
                                        <h3>{feature.name}</h3>
                                        <p className="my-2" key={index}>{feature.description}</p>
                                    </div>                                    
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* imágenes */}
                <div className="container w-full">
                    <div className="flex items-center object-contain justify-center gap-4">
                        <img className="max-h-[300px]" src={buceo1} alt="" />
                        <img className="max-h-[300px]" src={buceo3} alt="" />
                    </div>
                </div>
                {/* nuestros equipos */}
                <div className="mx-auto max-w-2xl pt-10 sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nuestros Buzos y Equipos</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Ofrecemos una amplia gama de servicios de buceo profesional, adaptados a las necesidades de cada proyecto. Contamos con buzos comerciales, intermedios y básicos, operando desde la X a la XII región del país. Equipados con tecnología avanzada, incluyendo equipos semi autónomos, hidrolavadoras, globos de levante y herramientas submarinas neumáticas y eléctricas, garantizamos soluciones eficientes y seguras para todas tus operaciones subacuáticas.
                    </p>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default BuceoProfesional