import React from 'react'
import { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HeroAlianzas from '../Components/HeroAlianzas';
import Partners2 from '../Components/Partners2';
import Certificaciones from '../Components/Certificaciones';


const Alianzas = () => {
    useEffect(() => {
        const loadLocomotiveScroll = async () => {
            const LocomotiveScroll = (await import("locomotive-scroll")).default;
            new LocomotiveScroll();
        };

        loadLocomotiveScroll();
    }, []);
    return (
        <div className="h-full overflow-x-hidden">
            <Header />
            <HeroAlianzas />
            <Partners2 />
            <Certificaciones />
            <Footer />
        </div>
    )
}

export default Alianzas