import React from "react";
import deeptrekker from "../assets/socios/deeptrekker.png";
import divers from "../assets/socios/divers_supply.png";
import dan from "../assets/socios/dan.png";
import achs from "../assets/socios/achs.svg";
import gcs from "../assets/socios/gcs.png";



const Partners = () => {
  return (
    <section id="socios" className="mx-auto w-full bg-down bg-contain bg-bottom bg-no-repeat py-20">
      <div className="mx-auto flex flex-col content-between max-w-7xl px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-gray-900 mb-10">
          Socios estratégicos
        </h2>
        <p className="text-md text-gray-600 mb-10">
        Nuestras alianzas con socios estratégicos son clave para ofrecer un servicio excepcional a nuestros clientes. Estas colaboraciones nos brindan acceso a tecnología de punta, recursos especializados y conocimientos avanzados, permitiéndonos entregar soluciones más eficientes y seguras. Gracias a estas alianzas, garantizamos la máxima calidad en cada proyecto, fortaleciendo la confianza y satisfacción de nuestros clientes.
        </p>
        <div className="mx-auto flex flex-col sm:flex-row justify-around flex-wrap sm:flex-nowrap gap-8 items-center mt-10">
            <div className="max-w-md flex flex-row justify-center flex-wrap lg:flex-nowrap gap-2">
              <img
                alt="Deeptrekker"
                src={deeptrekker}
                className="object-contain w-full h-36"
              />
              <img
                alt="Divers Supply"
                src={divers}
                className="object-contain w-full h-36"
              />
              <img
                alt="DAN"
                src={dan}
                className="object-contain w-full h-36"
              />
              <img
                alt="Asociación Chilena de Seguridad"
                src={achs}
                className="object-contain w-full h-36"
              />
              <img
                alt="GCS Consultores"
                src={gcs}
                className="object-contain w-full h-36"
              />
            </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;