import React from 'react';
import { Link } from 'react-router-dom';

const links = [
  {href: "/", name: "Inicio" },
  {href: '/buceo', name: "Buceo Profesional" },
  {href: '/robotica', name: "Robótica Submarina" },
  {href: '/nosotros', name: "Nosotros" },
  {href: '/clientes', name: "Clientes" },
  {href: '/alianzas', name: "Alianzas y Certificaciones" },
  {href: '/politica', name: "Política de Calidad" },
  {href: '/contacto', name: "Contacto" },
];
/*
const letterAnim = {
  initial: {
    y: "100%",
    opacity: 0,
  },
  enter: (i) => ({
    y: 0,
    opacity: 1,
    transition: {duration: 1, ease: [0.6, 0, 0.20, 1], delay: i[0] },
  }),
  exit: (i) => ({
    y: 0,
    opacity: 0,
    transition: {duration: 0.8, ease: [0.6, 0, 0.20, 1], delay: i[1] },
  }),
};

const getLetter = (name) => {
  let letters = [];
  name.split("").forEach((letter, index) => {
    letters.push(
      <motion.span 
        variants={letterAnim}
        initial="initial"
        animate="enter"
        exit="exit"
        custom={[index * 0.04, (name.length - index) * 0.01]}
        key={index}
      >
        {letter}
      </motion.span>
    );
  });
  return letters;
};
*/
const NavList = () => {
  return (
    <ul className="flex flex-col gap-8 font-primary text-4xl font-semibold text-accent items-center uppercase">
      {links.map((link, index) => {
        return (
          <Link to={link.href} key={index} scroll={true} className="flex overflow-hidden hover:text-white transition-all">
            {link.name}
          </Link>
        );
      })}
    </ul>
  );
};

export default NavList;