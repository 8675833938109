import React from 'react';
import pivot from '../assets/equipos/pivot.png';
import dtg3 from '../assets/equipos/dtg3.png';
import Separator from './Separator';

const Equipos = () => {
  return (
    <section id='Equipos' className="bg-blue-950">
      <div className="container mx-auto pt-24 pb-4 sm:pb-6 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Los mejores equipos para tus trabajos</h2>
            <p className="mt-6 text-lg leading-8 text-white">
            Contamos con ROVs de nuestra alianza con Deep Trekker modelos PIVOT y DTG3, estos adaptados con herramientas para costurado, corte y  recuperación de restos náufragos.
            </p>
          </div>
        </div>
        <Separator />
        {/* cuadros */}
        <div className='flex flex-col sm:flex-row justify-center object-contain mt-4 gap-2'>
          {/* pivot */}
          <div className='flex flex-col items-center mt-2 gap-2 '>
            {/* texto */}
            <div className='flex flex-col mx-3 items-center justify-center md:items-start md:justify-start'>
              <h2 className='text-white text-3xl mt-3'>
                Pivot
              </h2>
            </div>{/* imagen */}
            <img className='mt-3 h-[300px]' src={pivot} alt="" />
          </div>
          <div className='pt-6 sm:hidden'>
            <Separator />
          </div>
          {/* dgt3 */}
          <div className='flex flex-col items-center mt-2 gap-2 '>
            <div className='flex flex-col items-center justify-center md:items-start md:justify-start'>
            {/* texto */}
            <h2 className='text-white text-3xl mt-3'>
              DTG-3
            </h2>
            </div>
            {/* imagen */}
            <img className='mt-3 h-[300px]' src={dtg3} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Equipos