import React from "react";
import australis from "../assets/companies/australis.svg";
import yadran from "../assets/companies/yadran.png";
import camanchaca from "../assets/companies/camanchaca.png";
import novofish from "../assets/companies/novofish.png";


const Companies2 = () => {
  return (
    <section id="clientes" className="mx-auto bg-blue-950 w-full py-20">
      <div className="mx-auto flex flex-col content-between max-w-7xl px-6 lg:px-8 text-center">
        <p className="text-md text-white mb-10">
          Valoramos profundamente la confianza que nuestros clientes depositan en nosotros. Nos comprometemos a ofrecer soluciones seguras y de alta calidad, estableciendo relaciones duraderas basadas en la transparencia y la excelencia. Nuestro enfoque personalizado y nuestra dedicación a la satisfacción del cliente nos han permitido ganar la confianza de importantes empresas en el sector.
        </p>
        <div className="mx-auto px-4 flex flex-col sm:flex-row justify-around flex-wrap lg:flex-nowrap gap-8 items-center mt-10">
          <div className="max-w-md flex flex-row md:flex-row items-center justify-center flex-wrap lg:flex-nowrap gap-2">
            <img
              alt="Australis Seafoods"
              src={australis}
              className="object-contain w-full h-20 px-2"
            />
            <img
              alt="Yadran High Quality Salmon"
              src={yadran}
              className="object-contain w-full h-20 px-2"
            />
            <img
              alt="Camanchaca"
              src={camanchaca}
              className="object-contain w-full h-20 px-2"
            />
            <img
              alt="Novofish"
              src={novofish}
              className="object-contain w-full h-20 px-2"
            />
          </div>          
        </div>
      </div>
    </section>
  );
};

export default Companies2;