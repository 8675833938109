import { useEffect } from "react";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import Services from "../Components/Services";
import Comparative from "../Components/Comparative";
import Companies from "../Components/Companies";
import Partners from "../Components/Partners"
import About2 from "../Components/About2";
import Important from "../Components/Important";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Contact2 from "../Components/Contact2";
import Tecnologias from "../Components/Tecnologias";

const Home = () => {
    useEffect(() => {
        const loadLocomotiveScroll = async () => {
            const LocomotiveScroll = (await import("locomotive-scroll")).default;
            new LocomotiveScroll();
        };

        loadLocomotiveScroll();
    }, []);
    return (
        <div className="h-full overflow-x-hidden">
            <Header />
            <Hero />
            <Services />
            <About2 />
            <Comparative />
            <Companies />
            <Partners />
            <Important />
            <Tecnologias />
            <Contact2 />
            <Footer />
        </div>
    )
}

export default Home