import React from 'react'
import Separator from "./Separator";
import Badge from "./Badge";
import video from "../assets/hero/video.mp4";

const HeroNosotros = () => {
  return (
    <section className="h-[80vh] xl:h-screen relative text-white">
        <div className="bg-hero_overlay opacity-45 absolute w-full h-full z-10 bg-primary/[0.01]"></div>
        <video 
            autoPlay 
            loop 
            muted 
            className="absolute top-0 left-0 w-full h-full object-cover"
        >
            <source src={video} type="video/mp4" />
            Tu navegador no soporta el tag de video.
        </video>
        <div className="container mx-auto h-full flex flex-col xl:flex-row items-center z-30 relative">
            <div data-scroll data-scroll-speed="0.4" className="flex-1 flex flex-col text-center justify-center items-center xl:pb-12 gap-6 h-full">
                <div className="flex flex-col justify-around items-center pt-16 xl:gap-4 md:pt-9">
                    <Badge containerStyles="hidden xl:flex xl:w-[180px] xl:h-[140px]" />
                    <h1 className="h1 text-4xl lg:text-6xl text-white">
                        <span className="text-white">NUESTRA EMPRESA</span>
                    </h1>
                </div>                
                <Separator />
                <p>Desde nuestra fundación en 2014, Frogman ha sido impulsada por un equipo de profesionales altamente cualificados y comprometidos con la excelencia y la seguridad en cada proyecto. Nuestros valores fundamentales de seguridad, calidad e innovación nos guían en cada paso, mientras forjamos alianzas estratégicas y adoptamos tecnología de vanguardia. Nuestra historia refleja un crecimiento constante y una dedicación inquebrantable a superar las expectativas de nuestros clientes, consolidándonos como líderes en soluciones subacuáticas.</p>
            </div>
        </div>
    </section>
  )
}

export default HeroNosotros