import React from 'react';
import {FaFacebookF, FaInstagram} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'
const links = [
    {href: "/", name: "Inicio" },
    {href: '/buceo', name: "Buceo Profesional" },
    {href: '/robotica', name: "Robótica Submarina" },
    {href: '/nosotros', name: "Nosotros" },
    {href: '/politica', name: "Política de Calidad" },
    {href: '/clientes', name: "Clientes" },
    {href: '/alianzas', name: "Alianzas y Certificaciones" },
    {href: '/contacto', name: "Contacto" },
];

const Footer = () => {
    return (
        <footer className='bg-footer bg-cover bg-no-repeat pt-17 relative'>
            <div className="absolute top-0 w-full bg-black/[0.90] z-10"></div>
            <div className="container mx-auto z-20 relative">
                <div className="flex flex-col items-center justify-center gap-14">
                    <a href="/" className="relative py-8 w-[120px] h-[50px] mx-auto">
                        <img
                            src={logo}
                            fill
                            alt=""
                            className="object-contain"
                        />
                    </a>
                    <nav className="flex flex-col md:flex-row flex-wrap gap-3 lg:gap-6 justify-center items-center">
                        {links.map((link, index) => {
                            return (
                                <Link to={link.href} key={index} className="uppercase text-white tracking-widest hover:text-accent transition-all">
                                    <button className='border-solid border-white border-2 rounded-full hover:bg-blue-950 uppercase transition-all font-bold '><p className='mx-2 my-1'>{link.name}</p></button>
                                </Link>
                            );
                        })}
                    </nav>
                    <ul className="flex text-white text-xl gap-4">
                        <Link to="https://www.facebook.com/frogmanchile/" target='_blank' className="w-[54px] h-[54px] border border-white/[0.15] rounded-full flex items-center justify-center hover:text-accent transition-all">
                            <FaFacebookF />
                        </Link>
                        <Link to="https://www.instagram.com/frogmancl" target='_blank' className="w-[54px] h-[54px] border border-white/[0.15] rounded-full flex items-center justify-center hover:text-accent transition-all">
                            <FaInstagram />
                        </Link>
                    </ul>
                    <div className="border-t border-white/10 text-[15px] text-white/70 font-light w-full flex items-center justify-center py-6">
                        <p>&copy; Frogman 2024. Todos los derechos reservados. Desarrollo web por <Link to="https://github.com/DatMushu" target='_blank'>Manuel Díaz</Link></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer