import React from "react"
import badge from "../assets/frogman-sin-fondo-blanco.png";

const Badge = ({containerStyles}) => {
  return (
    <div className={`relative ${containerStyles}`}>
        <img src={badge} fill alt="" clasname="object-contain" />
    </div>
  )
}

export default Badge