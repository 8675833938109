import React from 'react'
import achs from "../assets/socios/achs.svg";
import iso9001 from "../assets/important/iso9001.png"
import iso45001 from "../assets/important/iso45001.png"
import pymes from "../assets/socios/pymes.png"
import minsal from "../assets/important/minsal.png"

const Certificaciones = () => {
    return (
        <section className="mx-auto w-full bg-blue-950 py-20">
          <div className="mx-auto flex flex-col content-between max-w-7xl px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-white mb-10">
              Acreditaciones
            </h2>
            <p className="text-md text-white mb-10">
                Nuestro equipo multidisciplinario tiene amplia experiencia en la industria. Contamos con acreditaciones y seguimos en proceso de obtener más, con el fin de brindar un servicio de la más alta calidad. Nos comprometemos a mantenernos a la vanguardia en nuestras prácticas y tecnologías para asegurar la satisfacción y seguridad de nuestros clientes
            </p>
            <div className="mx-auto mt-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
                    <div className="mx-4 my-4">
                        <h3 className="text-2xl font-bold tracking-tight text-white my-3">ACHS</h3>
                        <p className="my-4">
                            Protocolo de hiperbaria, protección UV aprobados, protocolo TEMERT en proceso de implementación
                        </p>
                        <img
                            alt="ACHS"
                            src={achs}
                            className="object-contain w-full h-36"
                        />
                    </div>
                    <div className="mx-4 my-4">
                        <h3 className="text-2xl font-bold tracking-tight text-white my-3">ISO 9001</h3>
                        <p className="my-4">
                            En proceso de acreditación de la norma ISO 9001. Norma gestión de la calidad más reconocida en el mundo
                        </p>
                        <img
                        alt="ISO 9001"
                        src={iso9001}
                        className="object-contain w-full h-36"
                        />
                    </div>
                    <div className="mx-4 my-4">
                        <h3 className="text-2xl font-bold tracking-tight text-white my-3">ISO 45001</h3>
                        <p className="my-4">
                            En proceso de acreditación de la norma ISO 45001.
                            Norma internacional para la gestión de sistema de seguridad y salud en el trabajo
                        </p>
                        <img
                        alt="ISO 45001"
                        src={iso45001}
                        className="object-contain w-full h-36"
                        />
                    </div>
                    <div className="mx-4 my-4">
                        <h3 className="text-2xl font-bold tracking-tight text-white my-3">Código Pymes</h3>
                        <p className="my-4">
                            Inscritos en el registro nacional de Pymes del ministerio de economía, fomento y turismo
                        </p>
                        <img
                        alt="Código Pymes"
                        src={pymes}
                        className="object-contain w-full h-36"
                        />
                    </div>
                    <div className="sm:col-span-2 mx-4 my-4">
                        <h3 className="text-2xl font-bold tracking-tight text-white my-3">Resolución</h3>
                        <p className="my-4">
                            Empresa autorizada para efectuar desinfección por resolución  MINSAL Nº2410247474 del 23 de Julio del 2024
                        </p>
                        <img
                        alt="Resolución minsal"
                        src={minsal}
                        className="object-contain w-full h-36"
                        />
                    </div>
                </div>
            </div>
          </div>
        </section>
      );
}

export default Certificaciones