import React from "react";
import deeptrekker from "../assets/socios/deeptrekker.png";
import divers from "../assets/socios/divers_supply.png";
import dan from "../assets/socios/dan.png";
import achs from "../assets/socios/achs.svg";
import gcs from "../assets/socios/gcs.png";



const Partners2 = () => {
  return (
    <section id="socios" className="mx-auto w-full bg-down bg-contain bg-bottom bg-no-repeat py-20">
      <div className="mx-auto flex flex-col content-between max-w-7xl px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-gray-900 mb-10">
          Socios estratégicos
        </h2>
        <p className="text-md text-gray-600 mb-10">
        Nuestras alianzas con socios estratégicos son clave para ofrecer un servicio excepcional a nuestros clientes. Estas colaboraciones nos brindan acceso a tecnología de punta, recursos especializados y conocimientos avanzados, permitiéndonos entregar soluciones más eficientes y seguras. Gracias a estas alianzas, garantizamos la máxima calidad en cada proyecto, fortaleciendo la confianza y satisfacción de nuestros clientes.
        </p>
        <div className="mx-auto mt-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
                <div className="mx-4 my-4">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 my-3">Deep Trekker</h3>
                    <p className="my-4">
                        Alianza para la adquisición de ROV y desarrollo de tecnologías para adaptar los equipos a la industria de la salmonicultura
                    </p>
                    <img
                        alt="Deeptrekker"
                        src={deeptrekker}
                        className="object-contain w-full h-36"
                    />
                </div>
                <div className="mx-4 my-4">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 my-3">Divers Supply</h3>
                    <p className="my-4">
                        Alianza para compra y desarrollo de consolas de aire adaptadas a la realidad nacional, equipos de buceo comercial y cámaras hiperbáricas
                    </p>
                    <img
                    alt="Divers Supply"
                    src={divers}
                    className="object-contain w-full h-36"
                    />
                </div>
                <div className="mx-4 my-4">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 my-3">Dan</h3>
                    <p className="my-4">
                        Formamos parte de la Divers Alert Network pudiendo acceder a información y equipos relacionados con oxigenoterapia
                    </p>
                    <img
                    alt="DAN"
                    src={dan}
                    className="object-contain w-full h-36"
                    />
                </div>
                <div className="mx-4 my-4">
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 my-3">GCS Consultores</h3>
                    <p className="my-4">
                        Alianza para la implementación y acreditación de las normas de calidad ISO 9001 y 45001
                    </p>
                    <img
                    alt="GCS Consultores"
                    src={gcs}
                    className="object-contain w-full h-36"
                    />
                </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Partners2;