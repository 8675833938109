import React from 'react'
import Swal from 'sweetalert2'

const Contact2 = () => {

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "e3c7f0a2-a2aa-48b9-81d8-ced81d56c9ab");
        
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            Swal.fire({
                title: "Mensaje Enviado",
                text: "Responderemos pronto al correo indicado.",
                icon: "success"
            });
        }
    };

    return (
        <section id="contacto" className="bg-blue-950 mx-auto w-full">
            <div className="container mx-auto max-w-2xl pt-6 pb-10">
                <div className="py-4">
                    <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl text-white mb-10">Contáctanos</h2>
                    <form onSubmit={onSubmit}>
                        <div className="flex flex-row flex-wrap md:flex-nowrap gap-4">
                            <div className="w-full flex flex-col">
                                <label className='text-white'>Nombre</label>
                                <input 
                                    type="text"
                                    required
                                    name='name'
                                    className="p-4 bg-gray-50 border border-gray-100" 
                                />
                            </div>
                            <div className="w-full flex flex-col">
                                <label className='text-white'>E-Mail</label>
                                <input 
                                    type="email"
                                    name='email'
                                    required
                                    className="p-4 bg-gray-50 border border-gray-100" 
                                />
                            </div>
                        </div>
                        <div>
                            <label className='text-white'>Mensaje</label>
                            <textarea 
                                rows={5}
                                required
                                name='message'
                                minLength={10}
                                maxLength={500}
                                placeholder="¿Cómo podemos ayudarle?"
                                className="w-full p-4 bg-gray-50 border border-gray-100 "
                            />
                        </div>
                        <button type="submit" className="btn">Enviar Mensaje</button> 
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contact2