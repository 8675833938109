"use client";
import { Link } from 'react-router-dom'

const Comparative = () => {
  return (
    <div className="bg-colores bg-contain bg-no-repeat">
        <div className="py-24 sm:py-32">
            <div className="mx-auto flex flex-col max-w-7xl px-6 gap-4 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Explora nuestros servicios</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Ofrecemos una amplia gama de servicios submarinos diseñados para satisfacer las necesidades más exigentes. Desde buceo profesional hasta robótica submarina avanzada, nuestro equipo altamente cualificado y nuestra tecnología de vanguardia garantizan soluciones seguras y eficientes para cada proyecto. Descubre cómo podemos ayudarte a alcanzar tus objetivos con la máxima calidad y seguridad.
                    </p>
                </div>
{/*
                <div className="max-w-full flex flex-col md:flex-row justify-around gap-2">
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Básico</h3>
                            <h2><span>Profundidad:</span> 20 metros</h2>
                            {/*
                                <div className="mt-10 flex items-center gap-x-4">
                                    <h4 className="flex-none text-sm font-semibold leading-6 text-accent">¿Qué incluye?</h4>
                                    <div className="h-px flex-auto bg-gray-100" />
                                </div>
                                <ul
                                role="list"
                                className="mt-8 flex flex-col gap-4 text-sm leading-6 text-gray-600"
                                >
                                {includedFeatures1.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                    <FaCheckCircle aria-hidden="true" className="h-6 w-5 flex-none text-accent" />
                                    {feature}
                                    </li>
                                ))}
                                </ul>
                            
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Intermedio</h3>
                            <h2><span>Profundidad:</span> 36 metros</h2>
                            
                                <div className="mt-10 flex items-center gap-x-4">
                                    <h4 className="flex-none text-sm font-semibold leading-6 text-accent">¿Qué incluye?</h4>
                                    <div className="h-px flex-auto bg-gray-100" />
                                </div>
                                <ul
                                role="list"
                                className="mt-8 flex flex-col gap-4 text-sm leading-6 text-gray-600"
                                >
                                {includedFeatures2.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                    <FaCheckCircle aria-hidden="true" className="h-6 w-5 flex-none text-accent" />
                                    {feature}
                                    </li>
                                ))}
                                </ul>
                            
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Robótica</h3>
                            <h2><span>Profundidad:</span> 100 metros</h2>
                            {/*
                            <div className="mt-10 flex items-center gap-x-4">
                                <h4 className="flex-none text-sm font-semibold leading-6 text-accent">¿Qué incluye?</h4>
                                <div className="h-px flex-auto bg-gray-100" />
                            </div>
                            <ul
                            role="list"
                            className="mt-8 flex flex-col gap-4 text-sm leading-6 text-gray-600"
                            >
                            {includedFeatures3.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                <FaCheckCircle aria-hidden="true" className="h-6 w-5 flex-none text-accent" />
                                {feature}
                                </li>
                            ))}
                            </ul>
                            
                        </div>
                    </div>
                    <div className="mx-auto mt-3 w-full md:w-auto rounded-3xl ring-1 ring-gray-200 sm:mt-6">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Buceo Comercial</h3>
                            <h2><span>Profundidad:</span> A requerimiento</h2>
                            {/*
                            <div className="mt-10 flex items-center gap-x-4">
                                <h4 className="flex-none text-sm font-semibold leading-6 text-accent">¿Qué incluye?</h4>
                                <div className="h-px flex-auto bg-gray-100" />
                            </div>
                            <ul
                            role="list"
                            className="mt-8 flex flex-col gap-4 text-sm leading-6 text-gray-600"
                            >
                            {includedFeatures4.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                <FaCheckCircle aria-hidden="true" className="h-6 w-5 flex-none text-accent" />
                                {feature}
                                </li>
                            ))}
                            </ul>
                            
                        </div>
                    </div>
                </div>

                <div className="max-w-full flex justify-center items-center gap-2 py-4">
                <Link to="/contacto"><button className="btn">Ver más</button></Link>
                </div>
                */}
            </div>
        </div>
    </div>
    
  )
}

export default Comparative