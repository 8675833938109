import React from "react";
import qr from "../assets/important/qr.png";
import iso9001 from "../assets/important/iso9001.png";
import iso45001 from "../assets/important/iso45001.png";
import separador from "../assets/separator-vertical.png";

export default function Example() {
  return (
    <div className="bg-blue-950 py-12 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Continuamos mejorando para nuestros clientes
          </p>
          <p className="mt-6 text-lg leading-8 text-white">
            Nos enorgullece mantener los más altos estándares de calidad y seguridad. Estamos comprometidos con la excelencia y la mejora continua, asegurando que nuestros clientes reciban siempre el mejor servicio posible. A medida que avanzamos, seguimos implementando prácticas de gestión de calidad y seguridad de primer nivel, con el objetivo de obtener certificaciones que respalden nuestro compromiso.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {/* col1 */}
            <div className="relative flex flex-col content-between text-center lg:text-right justify-stretch pl-16">
                <dt className="text-base font-semibold leading-7 text-white">
                    {/* título */}
                    Registro Pymes
                </dt>
                <dd className="mt-2 text-base leading-7 text-white">
                    {/* descripción */}
                    Contamos con inscripción en el Registro Nacional de Pymes del Ministerio de Economía de Chile. Esta inscripción refuerza nuestro compromiso con la transparencia y la mejora continua, consolidando nuestra posición como una empresa innovadora y en crecimiento.
                </dd>
                <br/>
                <dd>
                    <img 
                        src={qr}
                        className="object-contain w-full pt-3 h-36"
                        alt=""
                    />
                </dd>
            </div>
            {/*
            <div className="">
              <img className="max-h-[200px]" src={separador} alt="" />
            </div>
            */}
            {/* col2 */}
            <div className="relative flex flex-col content-between text-center lg:text-left justify-center pl-16">
                <dt className="text-base font-semibold leading-7 text-white">
                    {/* título */}
                    Certificaciones
                </dt>
                <dd className="mt-2 text-base leading-7 text-white">
                    {/* descripción */}
                    Estamos en proceso de obtener las certificaciones ISO 9001 y ISO 45001, que reflejan nuestro compromiso con la gestión de calidad y la seguridad en el trabajo. Estas certificaciones son un paso importante en nuestra misión de ofrecer servicios de excelencia a nuestros clientes.
                </dd>
                <dd>
                    <div className="flex flex-row items-center flex-wrap sm:flex-nowrap gap-2">
                        <img 
                            src={iso9001}
                            className="object-contain w-full pt-3 h-36"
                            alt=""
                        />
                        <img 
                            src={iso45001}
                            className="object-contain w-full pt-3 h-36"
                            alt=""
                        />
                    </div>
                </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}